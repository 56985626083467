import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { getModulesProgress } from '../../models/modules';

import css from './ProgressPage.module.css'

const ProgressPage = () => {

    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn, hasPurchase, verifyPurchase } = useAuth();
    const navigate = useNavigate();

    const [showCompleted, setShowCompleted] = useState(false);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        const fetchModules = async () => {
            const response = await getModulesProgress(authUser.id);
            setModules(response);
        }
        
        if (isLoggedIn && authUser) {
            fetchModules();
        }
    }, [authUser, isLoggedIn]);

    return (
        <div className={css.container}>
            <h1>Te damos la bienvenida</h1>
            <h2>
                {authUser && authUser.name}
            </h2>
            <div className={css.modules}>
                {modules && modules.map((module, index) => (
                    <div key={index} className={css.moduleCard} onClick={() => navigate("/vocacionamiento?module=" + module.id_module)}>
                    <img src={module.module_image || "/banner.png"} alt={module.title} className={css.moduleImage} />
                    <div className={css.moduleInfo}>
                      <h3>{module.module_title}</h3>
                      <div className={css.progressBar}>
                        <div className={css.progress} style={{ width: `${module.progress}%` }}></div>
                      </div>
                      <p>Progreso: {parseInt(module.progress)}%</p>
                    </div>
                  </div>
                ))}
            </div>
        </div>
    )
}

export default ProgressPage;