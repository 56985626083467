import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Login.module.css";
import logo from "../../assets/pivotea-logo-dark.png";
import Timer from "../../components/Timer/Timer";
import Cookies from "js-cookie";

export default function Login() {
  const navigate = useNavigate();
  const { setAuthUser, isLoggedIn, setIsLoggedIn, verifyPurchase } = useAuth();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [success, setSuccess] = useState(false);
  const [timer, setTimer] = useState(false);
  const time = useRef(null);

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  const [disabledBtn, setDisabledBtn] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    } else {
      const authCookie = Cookies.get("authUser");
      if (authCookie) {
        const user = JSON.parse(authCookie);
        setAuthUser(user);
        setIsLoggedIn(true);
        navigate("/");
      }
    }
  }, [isLoggedIn, navigate, setAuthUser, setIsLoggedIn]);

  const onLogin = async () => {
    try {
      const response = await axios.post(url + `/auth/login-as`, {
        email: email,
        password: pass,
      });
      setIsLoggedIn(true);

      Cookies.set("authUser", JSON.stringify(response.data.data.user), { expires: 7 });

      setAuthUser({
        name: response.data.data.user.name,
        lastName: response.data.data.user.last_name,
        id: response.data.data.user.id_user,
        token: response.data.token,
        current_module: response.data.data.user.current_module,
        current_step: response.data.data.user.current_step,
        email: response.data.data.user.email,
      });
      navigate("/");
    } catch (error) {
      setError(true);
    }
  };

  const onRecovery = async () => {
    setDisabledBtn(true);
    try {
      await axios.post(url + `/auth/generate-recovery-token`, {
        email: email,
      });
      setSuccess(true);
      setError(false);
      const timerEnd = new Date();
      timerEnd.setSeconds(timerEnd.getSeconds() + 90);
      time.current = timerEnd;
      setTimer(true);
      setDisabledBtn(false);
    } catch (error) {
      setDisabledBtn(false);
      setError(true);
    }
  };

  const timerCallback = () => {
    setTimer(false);
  }

  return (
    <div className={styles.loginLayout}>
      <img src={logo} alt="Pivotea" />
      {!recovery && (
        <>
          <h1>Iniciar Sesión</h1>
          <div className={styles.loginForm}>
            <form>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Contraseña"
                id="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
              {error ? (
                <div className={styles.errorMsg}>
                  <p>El correo y/o contraseña es incorrecto.</p>
                </div>
              ) : null}
            </form>
            <button
              className={styles.link}
              onClick={() => {
                setRecovery(true);
              }}
            >
              ¿Olvidaste la contraseña?
            </button>
            <button className="btn btn-filled" onClick={onLogin}>
              Iniciar sesión
            </button>
          </div>
          <p>
            ¿Eres nuevo en este sitio?{" "}
            <span className={styles.link} onClick={() => navigate("/signup")}>
              Regístrate
            </span>
          </p>
        </>
      )}
      {recovery && (
        <>
          <div className={styles.loginForm}>
            <h3>Recuperar contraseña</h3>
            <p className={styles.passwordMsg}>
              Escribe tu correo registrado, si existe se enviará un correo con
              el enlace para recuperar tu cuenta.
            </p>
            <form>
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error ? (
                <div className={styles.errorMsg}>
                  <p>Hubo un error al intentar restablecer tu contraseña. Revisa que el correo que ingresaste sea correcto.</p>
                </div>
              ) : null}
              {success ? (
                <div className={styles.successMsg}>
                  <p>Se ha enviado un correo con los siguientes pasos.</p>
                </div>
              ) : null}
            </form>
            {timer && (
              <Timer expiryTimestamp={time.current} callback={timerCallback} />
            )}
            {!timer && (
              <button className="btn btn-filled" disabled={disabledBtn} onClick={onRecovery}>
                Recuperar tu contraseña
              </button>
            )}
            {timer && (
              <button disabled className="btn btn-disabled">
                Recuperar tu contraseña
              </button>
            )}
          </div>
          <button className={styles.link} onClick={() => { setError(false); setRecovery(false) }}>
            {" "}
            Regresar
          </button>
        </>
      )}
    </div>
  );
}